<template>
  <div class="propertyAdd">
    <!--面包屑-->
    <!-- <div class="breadcrumb"></div> -->
    <!--主体内容-->
    <div class="content bgFFF">
      <div class="commonF padding20">
        <h2 class="title">
          <div class="title_icon"></div>
          {{ isEdit ? "编辑" : "添加" }}物品
        </h2>
        <!--搜索条件区域-->
        <div class="">
          <el-form
            :inline="false"
            label-position="right"
            :model="formInline"
            class="demo-form-inline"
            ref="form"
            :rules="rules"
            label-width="100px"
          >
            <el-form-item :label="$t('searchModule.region')" prop="areaId">
              <el-button
                style="width: 200px"
                :title="areaName ? areaName : '请选择'"
                @click="areaIdDialog"
                class="dialog_button button_text_left"
                >{{ areaName ? areaName : "请选择" }}
              </el-button>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Merchant_Name')" prop="operationId">
              <el-select
                style="width: 200px"
                v-model.trim="formInline.operationId"
                filterable
                size="15"
                @change="getList(0)"
                :disabled="isEdit"
              >
                <!-- <el-option label="全部" value=""></el-option> -->
                <el-option
                  :label="value.operationName"
                  :value="value.operationId"
                  :key="value.operationId"
                  v-for="value in tenantList"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.serial_number')" prop="assetsCode">
              <el-input
                :maxlength="30"
                v-model.trim="formInline.assetsCode"
                style="width: 200px"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Item_Name')" prop="assetsName">
              <el-input
                :maxlength="20"
                v-model.trim="formInline.assetsName"
                style="width: 200px"
              ></el-input>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Type_of_item')" prop="assetsTypeParentId">
              <el-select
                style="width: 200px"
                v-model.trim="formInline.assetsTypeParentId"
                @change="getAssetsTypeList1(formInline.assetsTypeParentId, true)"
                filterable
                placeholder="请选择"
              >
                <el-option label="请选择" value=""></el-option>
                <el-option
                  :label="item.assetsTypeName"
                  :value="item.assetsTypeId"
                  v-for="item in assetsTypeList"
                  :key="item.assetsTypeId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Item_model')" prop="assetsTypeId">
              <el-select
                style="width: 200px"
                v-model.trim="formInline.assetsTypeId"
                filterable
                placeholder="请选择"
              >
                <el-option label="请选择" value=""></el-option>
                <el-option
                  :label="item.assetsTypeName"
                  :value="item.assetsTypeId"
                  v-for="item in assetsList"
                  :key="item.assetsTypeId"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.state')" prop="status">
              <el-select
                v-model.trim="formInline.status"
                filterable
                placeholder="请选择"
                style="width: 200px"
                @change="statusChange"
              >
                <el-option label="认领" :value="1"></el-option>
                <el-option label="空闲" :value="0"></el-option>
                <el-option v-if="isEdit" label="废弃" :value="2"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('searchModule.Recipient')" prop="pdaManagerId">
              <el-select
                style="width: 200px"
                v-model.trim="formInline.pdaManagerId"
                filterable
                size="15"
                :disabled="formInline.status === 0 || formInline.status === 2"
              >
                <el-option
                  :label="value.pdaManagerName"
                  :value="value.pdaManagerId"
                  :key="value.pdaManagerId"
                  v-for="value in pdaManagerList"
                ></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="支付终端号" prop="assetsCode">
              <el-input
                :maxlength="30"
                v-model.trim="formInline.assetsCode"
                style="width: 200px;"
              ></el-input>
            </el-form-item> -->
            <el-button type="primary" @click="submit()" style="margin-left: 100px">提交</el-button>
            <el-button @click="$router.go(-1)">取消</el-button>
          </el-form>
        </div>
      </div>
    </div>
    <tree-con
      ref="treeConpent"
      :defaultProps="defaultProps"
      :treeData="treeData"
      @treeSure="treeSure"
      @treeCandle="treeCandle"
    ></tree-con>
  </div>
</template>
<script>
import treeCon from "./tree";
export default {
  components: {
    treeCon,
  },
  data() {
    return {
      areaName: "",
      defaultProps: {
        label: "label",
        children: "children",
      },
      treeData: [],
      key1: "",
      tenantList: [],
      pdaManagerList: [],
      isEdit: false,
      rules: {
        areaId: [
          {
            required: true,
            message: "请选择区域",
            trigger: "blur",
          },
        ],
        operationId: [
          {
            required: true,
            message: "请选择商户",
            trigger: "blur",
          },
        ],
        assetsName: [
          {
            required: true,
            message: "请输入物品名称",
            trigger: "blur",
          },
        ],
        assetsTypeParentId: [
          {
            required: true,
            message: "请选择物品类型",
            trigger: "blur",
          },
        ],
        assetsCode: [
          {
            required: true,
            message: "请输入物品编号",
            trigger: "blur",
          },
        ],
        assetsTypeId: [
          {
            required: true,
            message: "请选择物品型号",
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: "请选择状态",
            trigger: "blur",
          },
        ],
        pdaManagerId: [
          {
            required: true,
            message: "请输入领用人",
            trigger: "blur",
          },
        ],
      },
      assetsTypeList: [],
      assetsList: [],
      formInline: {
        assetsCode: "",
        assetsTypeParentId: "",
        assetsTypeId: "",
        assetsName: "",
        status: 0,
        areaId: "",
        pdaManagerId: "",
        operationId: "", // 商户id
      },
      loading: false,
      assetsTypeName: "",
    };
  },
  watch: {},
  methods: {
    statusChange(val) {
      this.formInline.pdaManagerId = "";
      this.rules.pdaManagerId = [];
      this.setRules(val);
    },
    treeCandle() {},
    /* 商户名称的下拉框 */
    getTenantList() {
      this.$axios.get("/acb/2.0/operation/nameList").then((res) => {
        if (res.state == 0) {
          this.tenantList = res.value;
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    /* 人员列表 */
    getAllPdaManagerList() {
      let data = {
        operationId: this.formInline.operationId,
      };
      this.$axios
        .get("/acb/2.0/pdaManager/getAllPdaManager", {
          data,
        })
        .then((res) => {
          if (res.state == 0) {
            this.pdaManagerList = res.value;
          } else {
            this.$alert(res.desc, this.$t('pop_up.Tips'), {
              confirmButtonText: this.$t('pop_up.Determine'),
            });
          }
        });
    },
    getArea() {
      let url = "/acb/2.0/managerArea/areaAll";
      this.$axios.get(url).then((res) => {
        if (res.state == 0) {
          this.treeData = this.sortArea(res.value);
        } else {
          this.$alert(res.desc, this.$t('pop_up.Tips'), {
            confirmButtonText: this.$t('pop_up.Determine'),
          });
        }
      });
    },
    sortArea(res) {
      let arr = [];
      res.forEach((value) => {
        value.childs = [];
        if (value.parentId == 0) {
          arr.push(value);
        }
      });

      function getChild(data) {
        data.forEach((value) => {
          value.childs = res.filter((child) => {
            if (child.depth == 2) {
              child.disabled = true;
            }
            return child.parentId == value.areaId;
          });
          getChild(value.childs);
        });
      }

      getChild(arr);
      return arr;
    },
    areaIdDialog() {
      this.defaultProps = {
        label: "areaName",
        children: "childs",
      };
      this.key1 = "areaId";
      this.$refs.treeConpent.open();
    },
    treeSure(data) {
      if (data.length > 1) {
        this.$alert("只能选择一个节点！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      } else if (data.length == 0) {
        this.$alert("您未选择节点！", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine'),
        });
        return;
      }
      if (this.key1 == "areaId") {
        this.formInline[this.key1] = data[0]["areaId"];
        this.areaName = data[0]["areaName"];
      }
      this.$refs.treeConpent.close();
    },
    setRules(newVal) {
      if (newVal === 1) {
        this.rules.pdaManagerId = [
          {
            required: true,
            message: "请输入领用人",
            trigger: "blur",
          },
        ];
      } else {
        this.rules.pdaManagerId = [
          {
            required: false,
          },
        ];
      }
    },
    getAssetsTypeList1(id, flag) {
      if (flag) {
        this.formInline.assetsTypeId = "";
      }
      this.$axios
        .get("/acb/2.0/assetsType/allList", {
          data: {
            assetsTypeId1: id,
            type: 1,
          },
        })
        .then((res) => {
          this.assetsList = res.value.list;
        });
    },
    getList(type) {
      // 查询领用人列表
      this.getAllPdaManagerList();

      this.$axios
        .get("/acb/2.0/assetsType/allList", {
          data: {
            type,
            operationId: this.formInline.operationId,
          },
        })
        .then((res) => {
          if (res.state == 0) {
            if (type === 0) {
              this.assetsTypeList = res.value.list;
            }
          } else {
            this.$alert(res.desc, "提示");
          }
        });
    },
    submit() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let url = this.$route.query.assetsId ? `/acb/2.0/assets/update` : `/acb/2.0/assets/add`;
          this.$axios
            .post(url, {
              data: {
                ...this.formInline,
              },
            })
            .then((res) => {
              if (res.state == 0) {
                this.$router.go("-1");
              } else {
                this.$alert(res.desc, "提示");
              }
            });
        }
      });
    },
  },
  created() {
    this.getTenantList();
  },
  mounted() {
    this.setRules(this.formInline.status);
    if (this.$route.query.assetsId) {
      this.isEdit = true;
      this.formInline.assetsId = this.$route.query.assetsId;
      this.getAssetsTypeList1(this.$route.query.assetsTypeParentId);
      for (let i in this.formInline) {
        if (i == "status") {
          this.formInline[i] = parseInt(this.$route.query[i]);
        } else {
          this.formInline[i] = this.$route.query[i];
        }
      }
      this.areaName = this.$route.query.areaName;
      this.getList(0);
      this.getAllPdaManagerList();
    }
  },
};
</script>
<style lang="stylus" scoped>
.propertyAdd
  .breadcrumb
    height 30px
  .content
    overflow: hidden;
    border: 1px solid #c0ccda;
    border-radius: 4px;
    padding: 20px;
    .searchWrapper
      padding-left 30px
</style>
